<template>
  <div class="maxlayout container container__margin results-page">
    <div
      id="content"
      class="column"
      role="main"
      itemscope=""
      itemtype="http://schema.org/Article"
      style="margin-top:15px"
    >
      <h1 class="page__title title" id="page-title" itemprop="headline">
        Cookie policy
      </h1>
      <a id="main-content"></a>

      <div class="description">
         <p class="testo-introduttivo">
            I cookie sono file di piccole dimensioni inviati dai siti web visitati dagli utenti e memorizzati sul dispositivo utilizzato per accedere a tali siti. Quando gli utenti
            visitano nuovamente lo stesso sito, il browser legge i cookie memorizzati sul dispositivo e ritrasmette le informazioni al sito che originariamente ha creato i cookie.
            Anche il nostro Sito utilizza diverse tipologie di cookie e altre tecnologie di lettura e archiviazione di informazioni sul dispositivo dell'utente al fine, ad esempio,
            di effettuare analisi statistiche, personalizzare e facilitare l'esperienza di navigazione degli utenti e, previo consenso dell'utente, offrirti pubblicità basata sugli
            interessi dell'utente. Non utilizziamo cookie che hanno la possibilità di avviare programmi sui i dispositivi o di inviare virus sugli stessi.
          </p>
          <p class="testo-introduttivo">
            Il nostro Sito utilizza cookie tecnici, per i quali non è richiesto il consenso dell'utente, che sono necessari al funzionamento del Sito, inclusa la fornitura dei
            servizi da questo offerti. Questa categoria di cookie include gli analytics, i cookie di sessione e di funzionalità, impiegati dal titolare per, ad esempio, raccogliere
            informazioni, in forma aggregata, sul numero degli utenti e su come questi visitano il sito stesso, per salvare le preferenze di navigazione dell'utente, quali la
            lingua, o ancora per riconoscere l'utente che torna sul sito.
          </p>
          <p class="testo-introduttivo">
            Il nostro Sito utilizza anche cookie di profilazione propri e di terze parti, volti a creare profili relativi all'utente e utilizzati al fine di inviare e mostrare
            messaggi pubblicitari in linea con le preferenze manifestate dallo stesso durante la navigazione. Per i cookie di profilazione è necessario il consenso dell'utente.
          </p>
          <p class="testo-introduttivo">
            I cookie di cui sopra possono essere temporanei (quando sono automaticamente cancellati al termine del collegamento), permanenti (quando restano memorizzati nel disco
            fisso dell'utente, a meno che l'utente stesso non li cancelli), di prima parte (quando sono impostati e gestiti direttamente dal gestore del sito) e di terza parte
            (quando sono gestiti da un dominio differente da quello visitato dall'utente).
          </p>
          <p class="testo-introduttivo">Per informazioni scrivete a <a href="mailto:privacy@giuffrefl.it">privacy@giuffrefl.it</a></p>
          <br />

        <button
          id="ot-sdk-btn form-submit"
          class="ot-sdk-show-settings form-submit"
          onclick="loadPreferenceCenter(false)"
          style="border: none; padding: 10px; margin-bottom: 30px"
        >
          Impostazioni cookie
        </button>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ot-sdk-show-settings {
    border: none;
    padding: 10px;
    margin-bottom: 30px;
    background-color: #1e436b;
    color: #fff;

}
</style>